import * as React from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BackspaceIcon from '@mui/icons-material/Backspace';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import LogoMini from "src/components/common/LogoMini";
import { getTryoutParam } from "src/utils/Tryout";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";

const BottomNavigationTryoutActionCustom = styled(BottomNavigationAction)(
	({ theme }) => ({
		minWidth: "auto",
		"&.Mui-selected": {
			color: theme.palette.success.main,
			transform: "translateY(0px)",
			"& svg": {
				color: theme.palette.success.main,
				opacity: 1,
			},
		},
		"& svg": {
			fontSize: "1.5rem",
			color: theme.palette.text.secondary,
			opacity: 0.5,
		},
	})
);

export default function MobileBottomNavigationTryout() {
	const [value, setValue] = React.useState(1);
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams<{ id: string }>();
	const [isHide, setIsHide] = React.useState(true);
	const tryoutParam = getTryoutParam() || { membershipId: "" };

	React.useEffect(() => {
		switch (location.pathname) {
			case `/classes/tryout/membership/${tryoutParam.membershipId}`:
				setValue(0);
				break;
			case `/classes/tryout/${id}/start`:
				setValue(1);
				break;
			case "/all-menu":
				setValue(2);
				break;
			case `/classes/tryout/${id}/analytic`:
				setValue(3);
				break;
			case `/classes/tryout/${id}/ranking`:
				setValue(4);
				break;
		}
		if (location.pathname.includes("/classes/tryout/") && id) {
			if(	(location.pathname.includes("/start")) || 
				(location.pathname.includes("/analytic")) || 
				(location.pathname.includes("/ranking"))){
					setIsHide(false); /* Unhide bottom navigation when not in doing tryout */
			} else {
				setIsHide(true);
			}
		} else {
			setIsHide(true);
		}
	}, [location]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		switch (newValue) {
			case 0:
				navigate(`/classes/tryout/membership/${tryoutParam.membershipId}`);
				break;
			case 1:
				navigate(`/classes/tryout/${id}/start`);
				break;
			case 2:
				navigate("/all-menu");
				break;
			case 3:
				navigate(`/classes/tryout/${id}/analytic`);
				break;
			case 4:
				navigate(`/classes/tryout/${id}/ranking`);
				break;
		}
	};

	return (
		<>
			{isHide ? null : (
				<Box
					sx={{
						width: "100%",
						position: "fixed",
						bottom: 0,
						zIndex: 1000,
						backgroundColor: "background.paper",
						borderTop: 1,
						borderColor: "divider",
						borderRadius: "16px",
						boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
						border: "1px solid rgba(255, 255, 255, 0.69)",
						opacity: 0.9,
					}}>
					<Container>
						<BottomNavigation
							showLabels
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
								handleChange(event, newValue);
							}}
							sx={{ justifyContent: "space-around" }}>
							<BottomNavigationTryoutActionCustom
								label="Kembali"
								sx={{ position: "relative", left: "0px", px: 0 }}
								icon={
									value === 0 ? (
										<BackspaceOutlinedIcon />
									) : (
										<BackspaceIcon />
									)
								}
							/>
							<BottomNavigationTryoutActionCustom
								label="Mulai"
								sx={{ position: "relative", left: "10px", px: 0 }}
								icon={value === 1 ? <PlayCircleFilledWhiteIcon /> : <PlayCircleFilledWhiteOutlinedIcon />}
							/>
							<BottomNavigationTryoutActionCustom
								label=""
								sx={{ position: "relative", top: "-30px" }}
								icon={<LogoMini />}
							/>
							<BottomNavigationTryoutActionCustom
								label="Pembahasan"
								sx={{ position: "relative", left: "-10px", px: 0 }}
								icon={
									value === 3 ? (
										<AnalyticsIcon />
									) : (
										<AnalyticsOutlinedIcon />
									)
								}
							/>
							<BottomNavigationTryoutActionCustom
								label="Ranking"
								sx={{ position: "relative", left: "0px", px: 0 }}
								icon={value === 4 ? <EmojiEventsIcon /> : <EmojiEventsOutlinedIcon />}
							/>
						</BottomNavigation>
					</Container>
				</Box>
			)}
		</>
	);
}
