import { axiosInstance } from "./axiosInstance";

export const getCategories = async (filter: any) => {
	filter = Object.keys(filter)
		.map(function (key) {
			return key + "=" + filter[key];
		})
		.join("&");
	return await axiosInstance.get(`/api/feature/category?${filter}`);
};

export const getMainProgramCategories = async () => {
	// axiosInstance.defaults.baseURL = "https://api.brofesional.id";
	// const result = await axiosInstance.get(
	// 	`/api/feature/category/get-main-program-category`
	// );
	// axiosInstance.defaults.baseURL =
	// 	process.env.REACT_APP_API_URL || "https://api-dev.brofesional.id";

	return await axiosInstance.get(`/api/feature/mainprogramcategory`);
};
