import { configureStore } from "@reduxjs/toolkit";
import questionReducer from "../features/tryout/question/questionSlice";
import questionsReducer from "../features/tryout/questions/questionsSlice";
import answeredQuestion from "../features/tryout/answeredQuestion/answeredQuestionSlice";
import courseReducer from "../features/course/course/courseSlice";
import subMaterialVideoReducer from "src/features/course/course/subMaterialVideoslice";
import programReducer from "src/features/home/program/programSice";

export default configureStore({
	reducer: {
		question: questionReducer,
		questions: questionsReducer,
		answeredQuestion: answeredQuestion,
		course: courseReducer,
		subMaterialVideo: subMaterialVideoReducer,
		program: programReducer,
	},
});
