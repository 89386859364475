import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import AccessTimeFilledSharpIcon from "@mui/icons-material/AccessTimeFilledSharp";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";

export interface AnnouncementDialogProps {
	open: boolean;
	onClose: (value: string) => void;
	data: any;
}

export default function FloatingCountDown() {
	const [remainingTime, setRemainingTime] = React.useState(2);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const tentorPrivateEndTime = localStorage.getItem("tentorPrivateEndTime");
		console.log("tentorPrivateEndTime", tentorPrivateEndTime);
		if(!tentorPrivateEndTime){
			setRemainingTime(0);
		}
		const timer = setInterval(() => {
			const currentTime = new Date();
			if (remainingTime > 0) {
				setRemainingTime(
					tentorPrivateEndTime
						? Math.floor(
								(new Date(tentorPrivateEndTime).getTime() -
									currentTime.getTime()) /
									1000
						  )
						: remainingTime - 1
				);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [location]);

	useEffect(() => {
		if (remainingTime <= 6 && remainingTime >= 4) {
			navigate("/tentor/in-schedule");
		}
	}, [remainingTime]);

	const handleClickOpen = () => {
		if (remainingTime > 0) {
			navigate("/tentor/in-schedule");
		}
	};

	const formatTime = (time) => {
		if (time < 0) {
			return "00 : 00 : 00";
		}
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time % 3600) / 60);
		const seconds = time % 60;
		return `${hours < 10 ? "0" : ""}${hours} : ${
			minutes < 10 ? "0" : ""
		}${minutes} : ${seconds < 10 ? "0" : ""}${seconds}`;
	};

	return (
		<div>
			{/* {remainingTime <= 0 ? null : ( */}
				<Box
					sx={{
						position: "fixed",
						right: { xs: 16, sm: 24, md: 32, lg: 40 },
						top: { xs: 20, lg: 60 },
						display: remainingTime >= 2 ? "block" : "none",
						animation: "moving-box-annoucement 2s infinite",
						"@keyframes moving-box-annoucement": {
							"0%": {
								transform: "translateY(0)",
							},
							"50%": {
								transform: "translateY(-10px)",
							},
							"100%": {
								transform: "translateY(0)",
							},
						},
					}}>
					<Button
						onClick={handleClickOpen}
						size="large"
						startIcon={<AccessTimeFilledSharpIcon />}
						sx={{
							backgroundColor: "rgba(200, 200, 200, 0.8)",
						}}>
						{formatTime(remainingTime)}
					</Button>
				</Box>
			{/* )} */}
		</div>
	);
}
