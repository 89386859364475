import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProgramState {}

const initialState: ProgramState = {};

export const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        setProgram: (state, action: PayloadAction<ProgramState>) => {
            return {...action.payload};
        }
    }
})

export const { setProgram } = programSlice.actions;

export default programSlice.reducer