import * as React from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import BallotIcon from "@mui/icons-material/Ballot";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import QueueIcon from "@mui/icons-material/Queue";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from "@mui/icons-material/School";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoMini from "src/components/common/LogoMini";
import ContactsIcon from "@mui/icons-material/Contacts";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { logout } from "src/services/auth.service";

const BottomNavigationActionCustom = styled(BottomNavigationAction)(
	({ theme }) => ({
		minWidth: "auto",
		"&.Mui-selected": {
			color: theme.palette.success.main,
			transform: "translateY(0px)",
			"& svg": {
				color: theme.palette.success.main,
				opacity: 1,
			},
		},
		"& svg": {
			fontSize: "1.5rem",
			color: theme.palette.text.secondary,
			opacity: 0.5,
		},
	})
);

export default function MobileBottomNavigation() {
	const [value, setValue] = React.useState(0);
	const navigate = useNavigate();
	const location = useLocation();
	const [isHide, setIsHide] = React.useState(false);

	React.useEffect(() => {
		switch (location.pathname) {
			case "/tentor/profile":
				setValue(0);
				break;
			case "/tentor/schedule":
				setValue(1);
				break;
			case "/tentor/all-menu":
				setValue(2);
				break;
			case "/tentor/add-schedule":
				setValue(3);
				break;
			case "/tentor/class":
				setValue(4);
				break;
		}
	}, [location]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		switch (newValue) {
			case 0:
				navigate("/tentor/profile");
				break;
			case 1:
				navigate("/tentor/schedule");
				break;
			case 2:
				navigate("/tentor/all-menu");
				break;
			case 3:
				navigate("/tentor/add-schedule");
				break;
			case 4:
				navigate("/tentor/class");
				break;
		}
	};

	return (
		<>
			{isHide ? null : (
				<Box
					sx={{
						width: "100%",
						position: "fixed",
						bottom: 0,
						zIndex: 1000,
						backgroundColor: "background.paper",
						borderTop: 1,
						borderColor: "divider",
						borderRadius: "16px",
						boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
						border: "1px solid rgba(255, 255, 255, 0.69)",
						opacity: 0.9,
					}}>
					<Container maxWidth="md">
						<BottomNavigation
							showLabels
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
								handleChange(event, newValue);
							}}
							sx={{ justifyContent: "space-around" }}>
							<BottomNavigationActionCustom
								label="Profil"
								sx={{ position: "relative", left: "0px", px: 0 }}
								icon={value === 0 ? <ContactsIcon /> : <ContactsOutlinedIcon />}
							/>
							<BottomNavigationActionCustom
								label="Riwayat"
								sx={{ position: "relative", left: "10px", px: 0 }}
								icon={value === 1 ? <BallotIcon /> : <BallotOutlinedIcon />}
							/>
							<BottomNavigationActionCustom
								label=""
								sx={{ position: "relative", top: "-30px" }}
								icon={<LogoMini />}
							/>
							<BottomNavigationActionCustom
								label="Tambah"
								sx={{ position: "relative", left: "-10px", px: 0 }}
								icon={value === 3 ? <QueueIcon /> : <MoreTimeOutlinedIcon />}
							/>
							<BottomNavigationActionCustom
								label="Kelas"
								sx={{ position: "relative", left: "0px", px: 0 }}
								icon={value === 4 ? <CalendarMonthIcon /> : <CalendarMonthOutlinedIcon />}
							/>
						</BottomNavigation>
					</Container>
				</Box>
			)}
		</>
	);
}
